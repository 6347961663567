import React, { Component } from 'react';
import { graphql } from 'gatsby';
import ProductPreview from '../components/molecules/ProductPreview';
import PreHead from '../components/molecules/PreHead';
import SEO from '../components/molecules/SEO';
import ProductListTableHeader from '../components/molecules/ProductListTableHeader';
import ProductArchiveLayout from '../components/organisms/ProductArchiveLayout';


class Category extends Component {
  render() {

    const { contentfulCategory } = this.props.data;
    const { title, image, products, description } = contentfulCategory;

    return (
      <>
        <SEO title={`Category: ${title}`} description={description}/>
        <PreHead text={title} fluidImage={image.fluid}/>
        <ProductArchiveLayout>
          <ProductListTableHeader/>
          {products && products.map( node => (
            <ProductPreview key={node.id} product={node} vendors={this.props.data.allContentfulVendor}/>
          ) )}
        </ProductArchiveLayout>
      </>
    )
  }
}

export default Category;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulCategory(id: { eq: $id }) {
            title
            description
            image {
                fluid(maxWidth: 1000) {
                    sizes
                    src
                    srcSet
                    aspectRatio
                }
            }
            products {
                id
                title
                slug
                excerpt {
                    excerpt
                }
            }
        }
        allContentfulVendor {
            nodes {
                id
                title
                logo {
                    fluid(maxWidth: 1000) {
                        src
                        srcSet
                        sizes
                        aspectRatio
                    }
                }
                slug
                products {
                    id
                }
            }
        }
    }
`;
